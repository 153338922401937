import { TextMV } from "../types/commonTypes";

export const titleTexts: { [key: string]: TextMV } = {
  の謎MV: {
    kanji: "発見した謎",
    furigana: 'の<ruby data-ruby="なぞ">謎</ruby>',
  },
  のクイズMV: {
    kanji: "のクイズ",
    furigana: "のクイズ",
  },
  トリビアMV: {
    kanji: "トリビア",
    furigana: "トリビア",
  },
  アイテムを手に入れたMV: {
    kanji: "アイテムを手に入れた",
    furigana:
      'アイテムを<ruby data-ruby="て">手</ruby>に<ruby data-ruby="い">入</ruby>れた',
  },
  チケットを手に入れたMV: {
    kanji: "チケットを手に入れた",
    furigana:
      'チケットを<ruby data-ruby="て">手</ruby>に<ruby data-ruby="い">入</ruby>れた',
  },
};
