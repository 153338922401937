import { FC, useCallback, useEffect, useState } from "react";
import { useZxing } from "react-zxing";
import styles from "../ModalContents.module.scss";
import { useRouter } from "next/router";
import {
  qrCheckList,
  qrEmergencyCheckList,
} from "../../../../common/const/qrCheckList";
import { isString } from "../../../../common/types/typeGuard";
import { useUserStateHooks } from "../../../../common/hooks/userStateHooks";
import { useModal } from "../../hooks/modalHooks";
import { toast } from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import { ModalDataState } from "../../../../common/recoil/modalAtoms";

const QrcodeReader: FC = () => {
  const [result, setResult] = useState<string>("");
  const [avoidance, setAvoidance] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const setModalData = useSetRecoilState(ModalDataState);

  const router = useRouter();
  const { updateUserState } = useUserStateHooks();
  const [, setIsVisible] = useModal("infoOthers");

  const {
    query: { ver, orgId, course, room, content },
  } = router;

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);
    },
    []
  );

  const { ref } = useZxing({
    onResult(result) {
      setResult(result.getText());
    },
  });

  useEffect(() => {
    if (!isString(ver)) return;
    if (!isString(orgId)) return;
    if (!isString(course)) return;
    if (!isString(room)) return;
    if (!isString(content)) return;

    const value = qrCheckList[content];

    if (result.includes(value)) {
      updateUserState("reachSpot", content);

      toast.success("QRコードを読み取りました！");
      setModalData(null);

      setIsVisible(false);
    }
  }, [
    content,
    course,
    orgId,
    result,
    room,
    setIsVisible,
    setModalData,
    updateUserState,
    ver,
  ]);

  const check = () => {
    if (input.trim() === "") {
      return toast.error("キーワードを入力してください。");
    }
    if (!isString(content)) return;

    const value = qrEmergencyCheckList[content];

    if (!value.includes(input)) {
      return toast.error("キーワードが違います。");
    }

    updateUserState("reachSpot", content);

    toast.success("QRコードの発見を確認しました！");
    setModalData(null);

    setIsVisible(false);
  };

  return (
    <div className={`${styles.modalInner}`}>
      <div className={styles.QrWrap}>
        <video ref={ref} />
      </div>
      <button
        type="button"
        onClick={() => setAvoidance((s) => !s)}
        className={styles.btnHelp}
      >
        うまく読み取れない時は
      </button>
      {avoidance && (
        <div className={styles.acoWrap}>
          <p className={styles.acoText}>
            QRコード下に載っているキーワードを入力して送信してください。
          </p>
          <div className={styles.inputWrap}>
            <input
              value={input}
              onChange={onInputChange}
              placeholder="ここに入力"
              className={styles.acoInput}
            />
            <button onClick={() => check()} className={styles.btnSend}>
              送信
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QrcodeReader;
