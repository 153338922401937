import { useRouter } from "next/router";
import { toast } from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import { useSettingState } from "../../../../common/context/settingState.context";
import { useUserState } from "../../../../common/context/userState.context";
import { useUserStateHooks } from "../../../../common/hooks/userStateHooks";
import styles from "../ModalContents.module.scss";
import { DisplayMenuState } from "../../../../common/recoil/menuAtoms";
import { isSettingState } from "../../../../common/types/typeGuard";
import axios from "axios";
import { useCallback, useState } from "react";
import { ticketData } from "../../../../common/data/ticketData";

interface SetIdPasswordResponse {
  message: string;
}

const Setting: React.FC = () => {
  const { updateUserState } = useUserStateHooks();
  const setDisplayMenu = useSetRecoilState(DisplayMenuState);
  const { userState } = useUserState();
  const { settingState } = useSettingState();
  const router = useRouter();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);
    },
    []
  );

  if (!isSettingState(settingState)) return null;

  const { uid } = userState;
  const {
    query: { ver, orgId, course },
  } = router;
  const { withSampleMode, withTestMode } = settingState;

  const resetUserState = () => {
    toast.success("リセットしました。");
    setDisplayMenu("");
    updateUserState("reset", "none");
    router.push(`/${ver}/${orgId}/${course}`);
  };

  const deleteUsers = async () => {
    const data = {};

    const {
      data: { message },
    } = await axios.post<SetIdPasswordResponse>("/api/auth/deleteUser", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    toast.success(message);
  };

  const register = () => {
    if (course === "nazotoki" && input === "noguchi2024admin")
      return setIsVerified(true);
  };

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={`${styles.text} text-center`}>id : {uid}</div>

      {!isVerified && (
        <div className="mt-3">
          <p className="text-center text-sm">パスワードを送信してください</p>
          <input
            value={input}
            onChange={onInputChange}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-base placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
          />
          <div className="mt-3 text-center">
            <button
              type="button"
              className="inline-block rounded-md border border-transparent bg-blue-500 p-2 text-sm font-medium text-white"
              onClick={register}
            >
              送信
            </button>
          </div>
        </div>
      )}

      {(withSampleMode || withTestMode || isVerified) && (
        <div className={styles.text}>
          <div>
            {withSampleMode && (
              <p>
                サンプルモードのため、これまでのクリア履歴をリセットできます。
              </p>
            )}
          </div>
          <div>
            {withTestMode && (
              <p>
                テストモードのため、これまでのクリア履歴をリセットできます。
              </p>
            )}
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={resetUserState}
              className="m-1 rounded-md bg-amber-500 p-1"
            >
              クリア履歴をリセット
            </button>
          </div>

          {/* <div className="text-center">
            <button
              type="button"
              onClick={() => {
                const value = ticketData[0];

                updateUserState("gainTicket", value);
              }}
              className="m-1 rounded-md bg-green-500 p-1"
            >
              チケットを獲得
            </button>
          </div> */}
          {/* <div>
            <button
              type="button"
              className="m-1 rounded-md bg-amber-500 p-1"
              onClick={deleteUsers}
            >
              全ユーザー削除
            </button>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Setting;
