import { useRouter } from "next/router";
import { useState } from "react";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import { buttonTexts } from "../../../../common/const/buttonTexts";
import { divTexts } from "../../../../common/const/divTexts";
import { answerData } from "../../../../common/data/answerData";
import { Help } from "../../../../common/types/commonTypes";
import MultipleViewText from "../../../shared/MultipleViewText";
import styles from "../ModalContents.module.scss";

interface Props {
  help: Help;
}

const QuestHint: React.FC<Props> = ({ help }) => {
  const router = useRouter();
  const [showAdditionalHint, setShowAdditionalHint] = useState<boolean>(false);

  const { helpTitleMV } = help;
  const {
    query: {
      ver: verUrl,
      orgId: orgIdUrl,
      course: courseUrl,
      room: roomUrl,
      content: contentUrl,
    },
    asPath,
  } = router;

  const data = answerData.filter((data) => {
    const { ver, orgId, course, room, content } = data;

    if (
      ver === verUrl &&
      orgId === orgIdUrl &&
      course === courseUrl &&
      room === roomUrl &&
      content === contentUrl
    )
      return data;
  })[0];

  const { questHintMVArray } = data || {};

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          {/* <MultipleViewText text={helpTitleMV} /> */}
          謎解きのヒント
        </div>
      </div>
      {!contentUrl || !asPath.includes("quest") ? (
        <div className={styles.text}>
          <MultipleViewText text={divTexts.クエストが出題されている時はMV} />
        </div>
      ) : !questHintMVArray.length ? (
        <div className={styles.text}>ヒントはありません。</div>
      ) : (
        <div className={`${styles.text} px-3 pt-3 pb-6 text-base`}>
          <div>
            <MultipleViewText text={questHintMVArray[0]} />
          </div>
          {questHintMVArray.length > 1 && (
            <div>
              <button
                onClick={() => setShowAdditionalHint((s) => !s)}
                className="my-1 flex items-center justify-center rounded bg-slate-700 p-1 text-white"
              >
                <MultipleViewText text={buttonTexts.さらなるヒントはこちらMV} />
                <IoChevronDownCircleOutline aria-hidden="true" />
              </button>
              {showAdditionalHint && (
                <div className={styles.text}>
                  <MultipleViewText text={questHintMVArray[1]} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestHint;
