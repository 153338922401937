import { Quest } from "../types/questTypes";

export const questData: Quest[] = [
  {
    ver: "v1",
    orgId: "noguchi-sounkyo",
    course: "nazotoki",
    room: "room1",
    content: "content1",
    index: "quest1",
    type: "riddle",
    questType: "multipleImageT_multipleImageSwitchB",
    prizeType: "default",
    authCheckType: "questWithSpot",
    answerType: "katakana",
    spotNameMV: {
      kanji: "お土産店",
      furigana: "",
    },
    questTextMVArray: [],
    questStringArray: [],
    questImageUrlArray: [
      "/images/quest/nazotoki/content1_1",
      "/images/quest/nazotoki/content1_2",
      "/images/quest/nazotoki/content1_3",
    ],
    triviaArray: [
      {
        kanji:
          "手元のゲームシートの『観光』カードの空欄に、謎の答え「光」を書き込んでね。",
        furigana: "",
      },
    ],
    messageArray: [],
  },

  {
    ver: "v1",
    orgId: "noguchi-sounkyo",
    course: "nazotoki",
    room: "room1",
    content: "content2",
    index: "quest2",
    type: "riddle",
    questType: "animationT_textInputB",
    prizeType: "default",
    authCheckType: "questWithSpot",
    answerType: "katakana",
    spotNameMV: {
      kanji: "レストラン",
      furigana: "",
    },
    questTextMVArray: [
      {
        kanji: "",
        furigana: "",
      },
    ],
    questStringArray: ["5000", "buffet"],
    questImageUrlArray: [
      "/images/quest/nazotoki/content2_1_1",
      "/images/quest/nazotoki/content2_1_2",
      "/images/quest/nazotoki/content2_1_3",
      "/images/quest/nazotoki/content2_1_4",
      "/images/quest/nazotoki/content2_1_5",
      "/images/quest/nazotoki/content2_1_6",
      "/images/quest/nazotoki/content2_1_7",
      "/images/quest/nazotoki/content2_1_8",
      "/images/quest/nazotoki/content2_1_9",
      "/images/quest/nazotoki/content2_1_10",
      "/images/quest/nazotoki/content2_1_11",
      "/images/quest/nazotoki/content2_1_12",
      "/images/quest/nazotoki/content2_2_1",
      "/images/quest/nazotoki/content2_2_2",
      "/images/quest/nazotoki/content2_2_3",
      "/images/quest/nazotoki/content2_2_4",
      "/images/quest/nazotoki/content2_2_5",
      "/images/quest/nazotoki/content2_2_6",
      "/images/quest/nazotoki/content2_2_7",
      "/images/quest/nazotoki/content2_2_8",
      "/images/quest/nazotoki/content2_2_9",
      "/images/quest/nazotoki/content2_2_10",
      "/images/quest/nazotoki/content2_2_11",
      "/images/quest/nazotoki/content2_2_12",
    ],
    triviaArray: [
      {
        kanji:
          "手元のゲームシートの『食事』カードの空欄に、謎の答え「ジンギスカン」を書き込んでね。",
        furigana: "",
      },
    ],
    messageArray: [],
  },
  {
    ver: "v1",
    orgId: "noguchi-sounkyo",
    course: "nazotoki",
    room: "room1",
    content: "content3",
    index: "quest3",
    type: "riddle",
    questType: "longImageT_textInputB",
    prizeType: "default",
    authCheckType: "questWithSpot",
    answerType: "kanji",
    spotNameMV: {
      kanji: "フロント",
      furigana: "",
    },
    questTextMVArray: [],
    questStringArray: [],
    questImageUrlArray: ["/images/quest/nazotoki/content3_1"],
    triviaArray: [
      {
        kanji:
          "手元のゲームシートの『自然』カードの空欄に、謎の答え「夫婦滝」を書き込んでね。",
        furigana: "",
      },
    ],
    messageArray: [],
  },

  {
    ver: "v1",
    orgId: "noguchi-sounkyo",
    course: "nazotoki",
    room: "room1",
    content: "content4",
    index: "quest4",
    type: "riddle",
    questType: "multipleImageT_multipleImageSwitchB",
    prizeType: "default",
    authCheckType: "questWithSpot",
    answerType: "kanji",
    spotNameMV: {
      kanji: "大浴場",
      furigana: "",
    },
    questTextMVArray: [],
    questStringArray: ["left", "right", "right", "left"],
    questImageUrlArray: [
      "/images/quest/nazotoki/content4_1",
      "/images/quest/nazotoki/content4_2",
    ],
    triviaArray: [
      {
        kanji:
          "手元のゲームシートの『温泉』カードの空欄に、謎の答え「絶景」を書き込んでね。",
        furigana: "",
      },
    ],
    messageArray: [],
  },

  // {
  //   ver: "v1",
  //   orgId: "noguchi-sounkyo",
  //   course: "nazotoki",
  //   room: "room1",
  //   content: "content5",
  //   index: "quest5",
  //   type: "riddle",
  //   questType: "animationT_textInputB",
  //   prizeType: "default",
  //   authCheckType: "questWithSpot",
  //   answerType: "katakana",
  //   spotNameMV: {
  //     kanji: "6階",
  //     furigana: "",
  //   },
  //   questTextMVArray: [],
  //   questStringArray: ["5000", "stellar"],
  //   questImageUrlArray: [
  //     "/images/quest/nazotoki/content5_1",
  //     "/images/quest/nazotoki/content5_2",
  //     "/images/quest/nazotoki/content5_3",
  //     "/images/quest/nazotoki/content5_4",
  //   ],
  //   triviaArray: [
  //     {
  //       kanji:
  //         "ステラプレイスのセンター9階には、空と緑に囲まれて札幌の街並みを一望出来る屋上広場「そらのひろば ｽﾃﾗ9」があります。",
  //       furigana: "",
  //     },
  //   ],
  //   messageArray: [],
  // },

  {
    ver: "v1",
    orgId: "noguchi-sounkyo",
    course: "nazotoki",
    room: "room1",
    content: "final",
    index: "final",
    type: "riddle",
    questType: "souunkyoFinalT_souunkyoFinalB",
    prizeType: "final",
    authCheckType: "finalQuest",
    answerType: "katakana",
    spotNameMV: {
      kanji: "最後の謎",
      furigana: "",
    },
    questTextMVArray: [],
    questStringArray: [],
    questImageUrlArray: [],
    triviaArray: [
      {
        kanji: "",
        furigana: "",
      },
    ],
    messageArray: [],
  },
];
