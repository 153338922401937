import { HowToPlay } from "../types/modalTypes";

export const howToPlayData: HowToPlay[] = [
  {
    ver: "v1",
    orgId: "noguchi-sounkyo",
    course: "nazotoki",
    room: "room1",
    index: "howToPlay1",
    type: "default",
    textMVArray: [
      {
        kanji:
          "天空ホテルの謎に挑戦しよう！まずは4つの<span class='font-bold text-red-500'>たいせつな謎</span>を解き、手元のシートにある天空ホテルの<span class='font-bold text-red-500'>『魅力カード』</span>を完成させよう。4つの謎はどれから挑戦しても大丈夫だよ！",
        furigana: "",
      },
      {
        kanji:
          "それぞれのスポットには、謎解きQRコードが設置されているよ。指示に従ってQRコードを探し、専用のカメラで読み込もう。",
        furigana: "",
      },
      {
        kanji:
          "4つの謎を解くことで<span class='font-bold text-red-500'>最後の謎</span>に挑戦できるようになるよ！すべての謎をクリアした人には、チェックアウト時にノベルティをプレゼント！",
        furigana: "",
      },
      {
        kanji: "制限時間はないからじっくり考えてね。",
        furigana: "",
      },
      {
        kanji:
          "困った時はメニューの中にあるヘルプを確認してね！ヒントもあるよ。",
        furigana: "",
      },
    ],
  },
];
