import { Answer } from "../types/questTypes";

export const answerData: Answer[] = [
  {
    ver: "v1",
    orgId: "noguchi-sounkyo",
    course: "nazotoki",
    room: "room1",
    content: "content1",
    index: "answer1",
    answerArray: ["ヒカリ", "98265473"],
    questHintMVArray: [
      {
        kanji:
          "食べたものは箱の中に1文字ずつだけあるよ。食べた「後」の状態を考えよう。答えはカタカナ3文字。",
        furigana: "",
      },
    ],
    explanation:
      "3つの箱があります。実際の画面でボタンをタップすると、箱を切り替えることができます。指定されたお土産を食べた＝削除した、と考えると以下のようになります。お土産名”以外”のところがカタカナ1文字になります。3つのカタカナを繋げて答えは「ヒカリ」です。",
  },

  {
    ver: "v1",
    orgId: "noguchi-sounkyo",
    course: "nazotoki",
    room: "room1",
    content: "content2",
    index: "answer2",
    answerArray: ["ジンギスカン", "98265473"],
    questHintMVArray: [
      {
        kanji: "答えは6文字になるよ。1〜6に対応する文字を見つけよう！",
        furigana: "",
      },
    ],
    explanation:
      "バイキングで料理が配膳され、消費され、下げられる流れがアニメーションで表示されています。実際の画面でご確認ください。それぞれの配置に配膳されるお皿は、1回目が1 ■ ■や■ ■ 2のように数字がついている皿、2回目がジドリやミカンなど食べ物の名前が書いてある皿になっています。左上は「1 ■ ■」と「ジドリ」で、一はジとなります。同じ要領で1から6まで対応する文字を抜き出すと「ジンギスカン」になります。",
  },
  {
    ver: "v1",
    orgId: "noguchi-sounkyo",
    course: "nazotoki",
    room: "room1",
    content: "content3",
    index: "answer3",
    answerArray: ["夫婦滝", "98265473"],
    questHintMVArray: [
      {
        kanji:
          "滝が答えを導くので、まずは滝部分だけ読んでみよう。最初の画面では全部は見えてないかもしれない。",
        furigana: "",
      },
    ],
    explanation:
      "滝が答えを導くとあり、文字のうち滝の部分だけを読むと「滝の両側にある文字が手がかりです」と書いてあります。よって次は滝の両側だけを見ると「滝壺に答えあり」となります。最初の画面では滝壺は見えていませんが、実はこの画像は下にスクロールできるようになっており、ずっとスクロールしていくと滝壺に辿り着きます。書かれている答えは「夫婦滝」です。",
  },

  {
    ver: "v1",
    orgId: "noguchi-sounkyo",
    course: "nazotoki",
    room: "room1",
    content: "content4",
    index: "answer4",
    answerArray: ["絶景", "98265473"],
    questHintMVArray: [
      {
        kanji:
          "4つの温泉の名前は、それぞれ漢字1文字を表しているよ。答えは漢字2文字になるよ！",
        furigana: "",
      },
    ],
    explanation:
      "4つの温泉名は、それぞれ漢字1文字を示しています（たこいとのいと→凧糸の糸→糸）。漢字は糸、色、日、京で、それぞれの温泉の配置、サイズに合わせて並べることで、糸と色、日と京で漢字を作ることができます。答えは「絶景」です。",
  },
  // {
  //   ver: "v1",
  //   orgId: "noguchi-sounkyo",
  //   course: "nazotoki",
  //   room: "room1",
  //   content: "content5",
  //   index: "answer5",
  //   answerArray: ["オクジョウ"],
  //   questHintMVArray: [
  //     {
  //       kanji: "カタカナ5文字が隠れているよ。1文字目は「オ」。",
  //       furigana: "",
  //     },
  //   ],
  //   explanation:
  //     "星が並んでいる3枚の画像がアニメーションで順番に表示されています。全てを重ねてカタカナの文字を作ると「オクジョウ」が答えになります。",
  // },
  // {
  //   ver: "v1",
  //   orgId: "noguchi-sounkyo",
  //   course: "nazotoki",
  //   room: "room1",
  //   content: "content6",
  //   index: "answer6",
  //   answerArray: ["れきし"],
  //   questHintMVArray: [
  //     {
  //       kanji:
  //         "もみじの落ち葉がつくと「か」が「き」に、「は」が「ひ」になっているね。何か法則がありそうだ。他の落ち葉にもそれぞれ法則があるよ。",
  //       furigana: "",
  //     },
  //   ],
  //   explanation: "",
  // },
  {
    ver: "v1",
    orgId: "noguchi-sounkyo",
    course: "nazotoki",
    room: "room1",
    content: "final",
    index: "answer7",
    answerArray: ["マンキツ", "98265473"],
    questHintMVArray: [
      {
        kanji:
          "天空ホテルの魅力は何だっただろう？魅力とアイコンの対応はすでに出てきているよ。ここに来るまでに見た画面を確認しよう。",
        furigana: "",
      },
      {
        kanji:
          "この箱には天空ホテルの4つの魅力が入っているよ。天空ホテルの魅力と4つのピースをよく見比べみよう。答えは4文字になるよ。",
        furigana: "",
      },
    ],
    explanation: "",
  },
];
