import { useUserState } from "../../../../common/context/userState.context";
import { UserGainedTicket } from "../../../../common/types/userTypes";
import ImageComponent from "../../../shared/ImageComponent";
import MultipleViewText from "../../../shared/MultipleViewText";
import styles from "../ModalContents.module.scss";

interface Props {
  userGainedTicket: UserGainedTicket;
}

const AnyTime: React.FC<Props> = ({ userGainedTicket }) => {
  const { ticketNameMV, ticketImageUrl, ticketTextMVArray } = userGainedTicket;
  const { userState } = useUserState();

  const { uid, confirmedArray } = userState;

  return (
    <div className={`${styles.modalInner} ${styles.gainItem}`}>
      <div className={styles.itemName}>
        <div className={styles.itemNameInner}>
          <MultipleViewText text={ticketNameMV} />
        </div>
      </div>
      <div className={styles.imgWrap}>
        <div className={styles.imgInner}>
          <div className={styles.img}>
            <ImageComponent
              url={ticketImageUrl}
              alt={ticketNameMV?.kanji}
              width={400}
              height={200}
            />
          </div>
        </div>
      </div>
      <div className={styles.text}>
        <div>{ticketTextMVArray[0].kanji}</div>
        {/* <div className="my-3 text-center text-base font-bold">【応募方法】</div>
        <div className="flex items-start justify-start">
          <div>1.</div>
          <div>
            「応募画面へ」ボタンから応募フォームに進み、必要事項を入力のうえ応募してください。
          </div>
        </div>
        <div className="flex items-start justify-start">
          <div>2.</div>
          <div>「応募済み」の表示が出たら応募完了です。</div>
        </div>
        <div className="flex items-start justify-start">
          <div>3.</div>
          <div>応募可能期間は3/31 24:00までです。</div>
        </div> */}
      </div>
      {/* <div className={styles.btns}>
        {!confirmedArray.includes("ticketApplication") && (
          <div className="mt-3 text-center">
            <button
              type="button"
              className="inline-block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white focus:ring"
            >
              <a
                href={`https://form.run/@STELLAR?_field_22=${uid}`}
                // href={`https://www.google.co.jp/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                応募画面へ
              </a>
            </button>
          </div>
        )}
        {confirmedArray.includes("ticketApplication") && (
          <div className="mt-3 bg-slate-800 text-center font-bold text-white">
            <button type="button" className={styles.btnDone} disabled>
              応募済み
            </button>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default AnyTime;
