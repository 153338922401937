export const qrCheckList: { [key: string]: string } = {
  content1: "7dgxg6ryrA1kZxMUpe6pEK1K3BzvuxcHei6X3kTJHFU=",
  content2: "03NpOnWtY6SujDS8x5Q3Oe4JzbSYWNYYEcQdvrlwSaa=",
  content3: "SqEHLYAS5vIT0FhjwkxkhKfItqIBtNOkDR0gu3FZJfQ=",
  content4: "N7aSzTWO3kptVeMkF8WUkxUY7fDQy6Tgj3yzDVPXSpo=",
  content5: "XsIrye0GPLua6tFhuyyxInCM8djVtpSifqn6Rsetgko=",
  final: "u2G19fVWYVSpQsb7PnLlw3pp4AFShnMgbIQ7ez6bSqk=",
};

export const qrEmergencyCheckList: { [key: string]: string[] } = {
  content1: ["朝陽山", "98265473"],
  content2: ["黒岳", "98265473"],
  content3: ["桂月", "98265473"],
  content4: ["鳥の声", "98265473"],
  // content5: "ベビーケアルーム",
  final: ["川の囁き", "98265473"],
};
